<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      BackOffice: "Escritório Virtual",
      Menu: "Menu",
      Dashboard: "Painel",
      Store: "Loja",
      Products: "Produtos",
      Cart: "Carrinho",
      "My Orders": "Meus Pedidos",
      Categories: "Categorias",
    },
    es: {
      BackOffice: "Escritório Virtual",
      Menu: "Menu",
      Dashboard: "Panel",
      Store: "Tienda",
      Products: "Productos",
      Cart: "Cesta",
      "My Orders": "Mis Pedidos",
      Categories: "Categorias",
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      categories: {
        loading: true,
        list: [],
        errored: false,
      },
    };
  },
  mounted: function () {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }

    this.getCategories();
  },
  methods: {
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    getCategories() {
      if (!localStorage.categories) {
        api
          .get("store/categories")
          .then((response) => {
            if (response.data.status == "success") {
              this.categories.list = response.data.list;
              this.categories.loading = false;

              localStorage.categories = JSON.stringify(response.data.list);
            }
          })
          .catch((error) => {
            this.categories.errored = error;
            this.categories.loading = false;
          });
      } else {
        this.categories.list = JSON.parse(localStorage.categories);
        this.categories.loading = false;
      }
    },
  },
};
</script>

<template>
  <div id="sidebar-menu" class="pt-0">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="bg-soft-info">
        <router-link
          tag="a"
          to="/"
          class="side-nav-link-ref border-0 py-2 mt-1"
        >
          <i class="bx bx-home-circle"></i>
          <span class="notranslate">{{ t("BackOffice") }}</span>
        </router-link>
      </li>
      <li
        v-if="account.user.franchise.status == 'registered'"
        class="bg-soft-dark mt-1"
      >
        <router-link
          tag="a"
          to="/franchise"
          class="side-nav-link-ref border-0 py-2"
        >
          <i class="bx bx-building-house"></i>
          <span class="notranslate">{{ t("Franquia Home") }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t("Store") }}</li>
      <li>
        <router-link tag="a" to="/store/featured" class="side-nav-link-ref">
          <i class="bx bx-customize"></i>
          <span class="notranslate">{{ t("Products") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/store/cart" class="side-nav-link-ref">
          <i class="bx bx-cart"></i>
          <span class="notranslate">{{ t("Cart") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/store/orders" class="side-nav-link-ref">
          <i class="bx bx-list-ol"></i>
          <span class="notranslate">{{ t("My Orders") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/store/franchises" class="side-nav-link-ref">
          <i class="bx bx-building-house"></i>
          <span class="notranslate">{{ t("Franquias") }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t("Categories") }}</li>
      <li v-for="(cat, id) in categories.list" :key="id">
        <router-link
          tag="a"
          :to="'/store/category/' + cat.id"
          class="side-nav-link-ref"
        >
          <i class="bx bx-minus"></i>
          <span class="notranslate">{{ cat.name }}</span>
        </router-link>
        <ul v-if="cat.childs" class="sub-menu" aria-expanded="false">
          <li v-for="(child, ch) in cat.childs" :key="ch">
            <router-link
              tag="a"
              :to="'/store/category/' + child.id"
              class="side-nav-link-ref"
              >{{ child.name }}</router-link
            >
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.py-2 {
  padding: 0.9rem 1.5rem 0.7rem !important;
}
.py-2 i,
.py-2 span {
  line-height: 0px !important;
  padding-bottom: 0 !important;
}
.py-2 i {
  margin-top: -4px;
}
</style>
